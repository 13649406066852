function addCookieConsent() {
    /******************
     * Cookie Consent *
     ******************/

    const isConsumerFacing = ['homeasap.com', 'testhomeasap.com'].includes(window.location.hostname);
    
    // Create a script element to load the TermsFeed cookie consent JavaScript
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://www.termsfeed.com/public/cookie-consent/4.1.0/cookie-consent.js';
    script.charset = 'UTF-8';

    // Add an event listener for when the TermsFeed script is loaded
    script.onload = function () {
        cookieconsent.run({
            "notice_banner_type": "simple",
            "consent_type": "implied",
            "palette": "light",
            "language": "en",
            "page_load_consent_levels": ["strictly-necessary", "functionality", "tracking", "targeting"],
            "notice_banner_reject_button_hide": true,
            "preferences_center_close_button_hide": false,
            "page_refresh_confirmation_buttons": false,
            "website_name": "HomeASAP",
            "website_privacy_policy_url": isConsumerFacing ? undefined : "https://dashboard.homeasap.com/Directory/PrivacyPolicy",
            "notice_banner_insert_legal_urls": isConsumerFacing ? false : true,
            "cookie_domain": window.location.hostname.endsWith("testhomeasap.com") ? "testhomeasap.com" : "homeasap.com",
        });

        // Read More button
        const clampedText = document.getElementById("cc-nb-text");

        if (clampedText) {
            const style = document.createElement('style');
            style.textContent = `
                #cc-nb-text.expanded {-webkit-line-clamp: unset;}
                .cc-custom-read-more {
                  cursor: pointer;
                  text-decoration: underline !important;
                  text-align: right;
                  margin-top: -15px;
                }
                @media screen and (min-width: 601px) {
                  .cc-custom-read-more {
                    display: none !important;
                  }
                }
            `;
            document.head.appendChild(style);
            
            // Create the read more link element
            const readMoreLinkContainer = document.createElement("p");
            readMoreLinkContainer.style.textAlign = "right";
            readMoreLinkContainer.style.marginTop = "-15px";
            
            const readMoreLink = document.createElement("a");
            readMoreLink.className = "cc-custom-read-more";
            readMoreLink.textContent = "Read more";
            
            readMoreLinkContainer.appendChild(readMoreLink);
            
            // Append the read more link after the clamped text
            clampedText.insertAdjacentElement('afterend', readMoreLinkContainer);
            
            // Check if the text is actually clamped
            function toggleExpanded() {
                if (clampedText.classList.contains('expanded')) {
                    clampedText.classList.remove('expanded')
                    readMoreLink.textContent = 'Read more'
                } else {
                    clampedText.classList.add('expanded')
                    readMoreLink.textContent = 'Read less'
                }
            }
            
            // Toggle the expanded state
            readMoreLink.addEventListener("click", function() {
                toggleExpanded();
            });
        }
    };

    // Append the script element to the document head
    document.head.appendChild(script);

    // Apply the required CSS directly
    var style = document.createElement('style');
    style.innerHTML = `
.cc-nb-changep { display: none !important } 
.termsfeed-com---nb .cc-nb-main-container { padding: 25px 35px !important }
.termsfeed-com---nb-simple {
	max-width: 100% !important;
}
.termsfeed-com---nb .cc-nb-main-container {
	padding: 15px 35px !important;
	display: flex;
	justify-content: space-between;
	align-items: center !important;
}
.cc-nb-title-container {
	flex-basis: 300px;
}
.termsfeed-com---nb .cc-nb-title {
	font-size: 20px !important;
}
.cc-nb-title-container p {
	margin-bottom: 0 !important;
}
.cc-nb-text-container {
	padding: 0 35px;
}
.cc-nb-text-container p {
	margin-bottom: 0 !important;
}
.cc-nb-okagree {
	padding: 10px 50px !important;
	border-radius: 6px !important;
}


@media screen and (max-width: 600px) {

  .termsfeed-com---nb-simple {
    max-width: 100%;
  }
   .termsfeed-com---nb .cc-nb-main-container {
	flex-direction: column;
   }
  .cc-nb-title-container {
	flex-basis: 0;
    }
	.cc-nb-title-container p {
	margin-bottom: 5px !important;
    }
.cc-nb-text-container {
	padding: 0;
}
	.cc-nb-text-container p {
	overflow: scroll;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
		margin-bottom: 15px !important;
}
}
`;
    document.head.appendChild(style);
}

function populateContentAreaTemplates() {
	// Example: <template class="ha-templates" data-template-key="READ_Dashboard_Promo" data-template-class=""></template>
	for (let templateElement of Array.from(document.querySelectorAll('template.ha-templates[data-template-key]'))) {
	    fetch(`https://api.${window.location.href.includes('test') ? 'test' : ''}homeasap.com/NPlay.Services.NPlayApi/api/templates/${templateElement.dataset.templateKey}`)
	        .then(response => {
	            if (!response.ok) {
	                throw new Error('Network response was not ok');
	            }
	            return response.json();
	        })
	        .then(data => {
	            templateElement.outerHTML = '<div class="' + (templateElement.dataset.templateClass || '') + '">' + data + '</div>';
	        })
	        .catch(error => {
	            console.log('Cannot load template:', templateElement.dataset.templateKey, error);
	        });
	}
}

const isInIFrame = window.self !== window.top

if (!isInIFrame) {
    addCookieConsent()
}

populateContentAreaTemplates();
